import React from 'react';

const NextSteps = () => {
    return (
        <div className="md:tw-px-[10rem] md:tw-py-4 tw-p-4">
            {/* <h2><strong>About</strong></h2> */}

            <div className="tw-flex tw-flex-wrap">
                <div className="tw-w-full md:tw-w-11/12">
                    <h4 className='tw-text-[#37B048] tw-text-xl' >Next Steps and Resources</h4>

                    <p className='tw-mt-4'>
                        After spending time thinking about your list of values and the questions on the Results page you asked
                        yourself, you may be ready for additional steps in your career development journey. Some individuals can
                        clearly see what to do next, while others may appreciate additional resources. Below are some suggestions.
                    </p>
                    <p className='tw-mt-4'>To explore your options for making a more satisfying connection between your lists of values, you may:</p>
                    <b>
                        <ul className="tw-list-disc tw-list-inside">
                            <li>Talk with a trusted family member or friend to brainstorm changes you can make in your life</li>
                            <li>Sit down with a colleague or supervisor to discuss your job tasks and goals in relation to your values</li>
                            <li>Search online, in the library, or at career centers for information about career options</li>
                            <li>Seek a counselor/coach to gain in-depth career development assistance.</li>
                        </ul>
                    </b>
                    <p className='tw-mt-4'>
                        If you are already working with a career counselor/coach, then it will be important to share your results and your ideas
                        with her/him. The career counselor/coach may provide guidance in the areas of career decision-making, job search, and life
                        planning so that you can move forward with confidence.
                    </p>

                    <p className="tw-text-blue-800 tw-font-bold">
                        If you are not already working with a career counselor/coach, you are strongly encouraged to connect with someone who can
                        help you utilize this assessment in a positive way.
                    </p>

                    <b>Finding a Career Counselor/Coach:</b>
                    <br />
                    <p className='tw-mt-4'>
                        Visit the National Career Development Association website at <a href="http://www.ncda.org">www.ncda.org</a> and click on
                        "Need Career Help?" on the home page. You can enter your zip code to locate a career counselor/coach in your area or click
                        on the link for a "more detailed search" to learn more about how to choose a counselor or the services of a career
                        counselor.
                    </p>
                    <p className='tw-mt-4'>
                        You can also locate a career counselor/coach by contacting the career center of a local college or university. Sometimes
                        the public library offers programs by career counselors/coaches.
                    </p>
                    <p className='tw-mt-4'>
                        The Career Development Alliance (CDA) also has a network of career counselors available by phone and email. Please{' '}
                        <a href="contact-us.cfm">Contact Us</a> for more details.
                    </p>

                    <h4 className='tw-text-[#37B048] tw-text-xl' >Need More Resources?</h4>

                    <p className='tw-mt-4'>
                        Values, while important, are just one part of you. More self-knowledge may be needed to gain a more satisfying life and
                        career. Other areas of assessment that may help an individual include interests, skills, and personality. Some of the most
                        widely used assessments include the
                    </p>
                    <ul className="tw-list-disc tw-list-inside">
                        <li>Strong Interest Inventory ©</li>
                        <li>Self-Directed Search</li>
                        <li>Campbell Interest and Skill Survey ©</li>
                        <li>SkillScan</li>
                        <li>Myers-Briggs Type Indicator ©</li>
                        <li>Keirsey Temperament Sorter-II ©</li>
                    </ul>
                    <p className='tw-mt-4'>
                        Several of these assessments may be available to you while working with a career counselor/coach. The above assessments are
                        just a few examples and are provided for informational purposes only. No recommendation, endorsement or warranty should be
                        interpreted or implied. Talk to your counselor/coach about all your options.
                    </p>

                    <p className='tw-mt-4'>
                        Additional resources have been identified as free and helpful and are listed under Resources on the website for the National
                        Career Development Association at <a target='_blank' href="https://www.ncda.org/aws/NCDA/pt/sp/home_page">www.ncda.org</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default NextSteps;
