import React, { useEffect, useLayoutEffect, useState } from 'react'
import "./NavBar.scss"
import { Link, NavLink, useNavigate } from 'react-router-dom'
import LifeCareerLogo from "../../assets/images/logo.png"
import { IToken } from '@Src/types/api-response-interface'
import { jwtDecode } from 'jwt-decode'
import { getDataFromLocalStorage } from '@Src/utils/globalUtilities'
import { LOCAL_STORAGE_DATA_KEYS } from '@Constants/localstorageDataModel'
import Joyride, { CallBackProps, STATUS, Step, } from "react-joyride";

const NavBar = () => {
    const [showMenu, setShowMenu] = useState(false)
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const navigate = useNavigate()
    const token = getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.AUTH_KEY);

    useEffect(() => {
        if (token) {
            const decodeToken: IToken = jwtDecode(token);
            const currentTime = Math.floor(Date.now() / 1000);
            if (decodeToken.exp < currentTime) {
                navigate('/')
                window.localStorage.clear()
                setIsLoggedIn(false)
            } else {
                setIsLoggedIn(true)
            }
        }
    }, [token])

    const logoutHandler = () => {
        setIsLoggedIn(false)
        navigate('/')
        window.localStorage.clear()
    }

    function scrollToSectionByClassName(className: string): void {
        const element = document.querySelector(`.${className}`);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.error(`Element with class name "${className}" not found.`);
        }
    }



    return (
        <div className='navbar'>
            <div className='tw-flex  md:tw-justify-between  tw-items-center tw-justify-between tw-gap-11'>
                <div className='tw-flex md:tw-w-auto tw-w-full tw-justify-between  tw-gap-11'>
                    <div className='md:tw-max-w-[240px] tw-max-w-40'>
                        <img src={`${LifeCareerLogo}`} />
                    </div>
                    <div className="sm:tw-hidden">
                        {/* side menu icon */}
                        <div onClick={toggleMenu} className="hamburger-icon ">
                            <svg viewBox="0 0 100 80" width="40" height="40">
                                <rect width="100" height="10" rx="10"></rect>
                                <rect y="30" width="100" height="10" rx="10"></rect>
                                <rect y="60" width="100" height="10" rx="10"></rect>
                            </svg>
                        </div>
                    </div>
                    {/* <div className='md:tw-flex tw-hidden tw-gap-4 tw-justify-center tw-items-center'>
                        <Link  to={'/assessment'} className='nav-items'>THE ASSESSMENT</Link>
                        <Link to={'/'} onClick={() => scrollToSectionByClassName('quotes-section')} className='nav-items'>ABOUT</Link>
                        <Link to={'/'} className='nav-items'>RESULTS</Link>
                        <Link to={'/nextSteps'} className='nav-items'>NEXT STEPS</Link>
                        <Link to={'/faq'} className='nav-items'>FAQS</Link>
                        <button onClick={() => scrollToSectionByClassName('footer-right-section')} className='nav-items'>CONTACT US</button>
                        {isLoggedIn && <Link to={'/dashboard'} className='nav-items'>DASHBOARD</Link>}
                        <Link to={`/home-video`} className='nav-items tw-uppercase'>Walkthrough</Link>
                    </div> */}
                    <div className='md:tw-flex tw-hidden tw-gap-4 tw-justify-center tw-items-center'>
                        <NavLink to="/about" onClick={() => scrollToSectionByClassName('quotes-section')} className={({ isActive }) => isActive ? 'nav-items nav-items-active' : 'nav-items'} >
                            HOMEPAGE
                        </NavLink>
                        {isLoggedIn ? (<NavLink to="/test/1" className={({ isActive }) => isActive ? 'nav-items nav-items-active' : 'nav-items'}>THE ASSESSMENT</NavLink>) : (<NavLink to="/assessment" className={({ isActive }) => isActive ? 'nav-items nav-items-active' : 'nav-items'}>THE ASSESSMENT</NavLink>)}

                        <NavLink to="/nextSteps" className={({ isActive }) => isActive ? 'nav-items nav-items-active' : 'nav-items'}>
                            NEXT STEPS
                        </NavLink>
                        <NavLink to="/faq" className={({ isActive }) => isActive ? 'nav-items nav-items-active' : 'nav-items'}>
                            FAQS
                        </NavLink>
                        {/* <button onClick={() => scrollToSectionByClassName('footer-right-section')} className="nav-items">
                            CONTACT US
                        </button> */}
                        {/* {isLoggedIn && (
                            <NavLink to="/dashboard"
                                className={({ isActive }) =>
                                    isActive ? 'nav-items nav-items-active' : 'nav-items'
                                }
                            >
                                DASHBOARD
                            </NavLink>
                        )} */}
                        {/* <NavLink to="/home-video"
                            className={({ isActive }) =>
                                isActive ? 'nav-items nav-items-active tw-uppercase' : 'nav-items tw-uppercase'
                            }
                        >
                            Walkthrough
                        </NavLink> */}
                    </div>
                </div>
                {!isLoggedIn ?
                    <div className='tw-hidden tw-gap-3 md:tw-flex '>
                        <button onClick={() => navigate('/login')} className='btn btn--green !tw-px-7 !tw-py-1 '>Get Started</button>
                        <NavLink to={`/login`} className={({ isActive }) => isActive ? 'nav-items nav-items-active btn btn--sky !tw-px-7 !tw-py-1 ' : 'nav-items btn btn--sky !tw-px-7 !tw-py-1 '}>LOGIN</NavLink>
                    </div>
                    :
                    <div className='tw-hidden tw-gap-3 md:tw-flex'>
                        <button onClick={() => navigate('/dashboard')} className='btn btn--green !tw-px-7 !tw-py-1'>Dashboard</button>
                        <button onClick={() => { logoutHandler() }} className='nav-items btn btn--sky !tw-px-7 !tw-py-1'>LOGOUT</button>
                    </div>
                }

            </div>
            {
                showMenu && <div className={`menu tw-fixed tw-top-0 tw-right-0 tw-z-20 tw-bg-[#37B048] tw-text-white tw-h-[100vh] tw-w-[100%] tw-flex tw-justify-center tw-items-center tw-flex-col tw-space-y-8 ${showMenu ? 'menu-open' : ''}`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="tw-absolute tw-top-4 tw-right-4 tw-cursor-pointer"
                        width="40"
                        height="40"
                        viewBox="0 0 24 24"
                        onClick={toggleMenu}
                    >
                        <path
                            fill="currentColor"
                            d="M18.364 5.636c-.78-.78-2.048-.78-2.828 0L12 9.172 8.464 5.636c-.78-.78-2.048-.78-2.828 0s-.78 2.048 0 2.828L9.172 12l-3.536 3.536c-.78.78-.78 2.048 0 2.828.78.78 2.048.78 2.828 0L12 14.828l3.536 3.536c.78.78 2.048.78 2.828 0 .78-.78.78-2.048 0-2.828L14.828 12l3.536-3.536c.78-.78.78-2.048 0-2.828z"
                        />
                    </svg>
                    {/* <div>
                        <Link to={`/home-video`} className="tw-font-bold tw-text-[25px] " onClick={(e) => { toggleMenu(); scrollToSectionByClassName('') }}>Home Video</Link>
                    </div> */}
                    <NavLink to="/about" onClick={() => { toggleMenu(); scrollToSectionByClassName('quotes-section') }} className={({ isActive }) => isActive ? 'nav-items nav-items-active' : 'nav-items'} >
                        HOMEPAGE
                    </NavLink>
                    {isLoggedIn ? (<NavLink onClick={() => { toggleMenu(); }} to="/test/1" className={({ isActive }) => isActive ? 'nav-items nav-items-active' : 'nav-items'}>THE ASSESSMENT</NavLink>) : (<NavLink onClick={() => { toggleMenu(); }} to="/assessment" className={({ isActive }) => isActive ? 'nav-items nav-items-active' : 'nav-items'}>THE ASSESSMENT</NavLink>)}

                    <NavLink onClick={() => toggleMenu()} to="/nextSteps" className={({ isActive }) => isActive ? 'nav-items nav-items-active' : 'nav-items'}>
                        NEXT STEPS
                    </NavLink>
                    <NavLink onClick={() => toggleMenu()} to="/faq" className={({ isActive }) => isActive ? 'nav-items nav-items-active' : 'nav-items'}>
                        FAQS
                    </NavLink>
                    {/* <button onClick={() => { toggleMenu(); scrollToSectionByClassName('footer-right-section') }} className="nav-items">
                        CONTACT US
                    </button> */}
                    {!isLoggedIn ?
                        <div>
                            <Link onClick={() => toggleMenu()} className="tw-font-bold tw-text-[25px]" to={`/login`}>Login</Link>
                        </div> :
                        <div className=' tw-gap-3 md:tw-flex'>
                            <button onClick={() => { logoutHandler(); toggleMenu() }} className='nav-items btn btn--sky !tw-px-7 !tw-py-1'>LOGOUT</button>
                        </div>}
                    {/* <div>
                        <Link className="tw-font-bold tw-text-[25px]" to={`/faq`} onClick={(e) => { toggleMenu(); scrollToSectionByClassName('') }}>Faq's</Link>
                    </div> */}
                </div>
            }
        </div >
    )
}

export default NavBar