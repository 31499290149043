import { IFinalValues } from '@Src/types/dashboard-interface'
import React from 'react'
import "../../LifeCareerPdf.scss"
const Test1Pdf = (props: { finalValues: IFinalValues | undefined, name: string }) => {
    return (
        <div className='test-1' style={{ fontFamily: 'Arial, sans-serif', padding: '0.5rem 2rem', width: "1100px", }}>
            <div className='important-whole-life-values page-class' >
                <h2 style={{ fontSize: '21px', fontWeight: '700' }} >{props.name} - Your Most Important Whole Life Values</h2>
                <ul style={{ marginLeft: '8px' }}>
                    {props.finalValues?.t1.map((value, index) => (
                        <li style={{ marginTop: '15px', fontSize: '21px', fontWeight: '600' }} key={index}>{index + 1} - {value.option_value}</li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Test1Pdf