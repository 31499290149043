import React from 'react'
import { IFinalValues } from '@Src/types/dashboard-interface'
import "../../LifeCareerPdf.scss"

const Test3Pdf = (props: { finalValuesData: IFinalValues | undefined, name: string }) => {
    return (
        <div className='test-3' style={{ fontFamily: 'Arial, sans-serif', padding: '0.5rem 2rem', width: "1100px", }}>
            <div style={{ marginTop: '40px' }} className='important-ideal-career-values page-class'>
                <h2 style={{ marginTop: '15px', fontSize: '21px', fontWeight: '700' }} >{props.name} - Your Most Important Ideal Career Values</h2>
                <ul style={{ marginLeft: '8px' }}>
                    {props.finalValuesData?.t3.map((value, index) => (
                        <li style={{ marginTop: '15px', fontSize: '21px', fontWeight: '600' }} key={index}>{index + 1} - {value.option_value}</li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Test3Pdf