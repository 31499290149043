import React from 'react'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import "./LifeCareerPdf.scss"
import { IFinalValues } from '@Src/types/dashboard-interface';

const LifeCareerPdf = (props: { finalValuesData: IFinalValues | undefined,name:string }) => {


    return (
        <div>
            <div className='pdf-downloader' style={{ fontFamily: 'Arial, sans-serif', padding: '0.5rem 2rem', width: "1100px", }}>
                <h1 style={{ fontSize: '32px', marginTop: '5px', fontWeight: '700' }} >{props.name} -- Your Important Values</h1>
                <p style={{ marginTop: '15px', fontSize: '21px', fontWeight: '600' }}>Your Results</p>
                <p style={{ marginTop: '10px', fontSize: '18px' }}>
                    Now that you have completed the assessment, you have three lists of your top ten values. <b style={{ color: '#37B048' }}>You can
                        now explore your results, section by section, comparing your values in each list.</b> It is helpful to
                    do this exploring with a counselor or coach. If you do not have a career counselor/coach, see the
                    <a className='tw-cursor-pointer tw-underline tw-text-blue-700' target='_blank' href=' https://lifecareervalues.web.app/nextSteps'> Next Steps</a>  section and look at Finding a Counselor/Coach.
                </p>
                <p style={{ marginTop: '10px', fontSize: '18px' }}>
                    As stated on our home page, "Values underlie every decision that we make. There is no choice or
                    decision, no matter how large or small, which cannot be traced back to your values.<b style={{ color: '#37B048' }}>" In order to
                        better understand your values, it is suggested that you spend time on the following exercises:</b>
                </p>

                <div style={{ marginBottom: '20px' }}>
                    <h3 style={{ marginTop: '15px', fontSize: '21px', fontWeight: '600' }} >Understanding Your Whole Life/Personal Values</h3>
                    <p style={{ marginTop: '15px', fontSize: '18px' }}>Begin with your "Whole Life/Personal Values" list.</p>

                    <ul style={{ marginTop: '15px', paddingLeft: '40px', listStyleType: 'decimal', fontSize: '18px' }}>
                        <li style={{ marginBottom: '10px', fontSize: '18px' }}>
                            Think about what each of these values mean to you and how they are displayed or put into
                            action in your life. Consider how your life would be different if you lost this value.<b style={{ color: '#37B048' }}> Define
                                each value with a short phrase or sentence that is meaningful to you.</b> Use a blank piece of
                            paper or write your definitions next to each word/phrase in your top ten list.
                        </li>
                        <li style={{ marginBottom: '10px', fontSize: '18px' }}>
                            <b style={{ color: '#37B048' }}>Now, look for any groupings or similarities in your top ten values.</b> Give these groupings (if
                            they exist) a title and summarize them. For example, several values relate to physical activities
                            or exercise - if you have these, you may wish to circle them and call them "My Health" and
                            describe how important it is for you to take care of yourself.
                        </li>
                        <li style={{ marginBottom: '10px', fontSize: '18px' }}>
                            Next, take a look at your top three to five values. Many believe that these values represent the
                            core of who you are. Think about whether or not this is true for you. <b style={{ color: '#37B048' }}>If you were going to
                                describe yourself to someone else, would these be among the more significant terms or
                                phrases you might use?</b> Why or why not? If you were only allowed to name two values and
                            you had to leave the rest behind, which two would you choose? Put a star next to those two.
                        </li>
                        <li style={{ marginBottom: '10px', fontSize: '18px' }}>
                            Finally, in what ways are you holding true to these values in your daily life? Do you ever find
                            yourself having to compromise these values? If so, why? If not, can you think of a time or
                            situation when you might be willing to compromise these values? <b style={{ color: '#37B048' }}>Make notes, write a story,
                                or draw pictures on the back of the results page that represents or reveals your values
                                each day.</b>
                        </li>
                    </ul>

                </div>

                <div style={{ marginBottom: '20px' }}>
                    <h3 style={{ marginTop: '15px', fontSize: '21px', fontWeight: '600' }} >Understanding Your Current/Most Recent Career Values</h3>
                    <p style={{ marginTop: '15px', fontSize: '18px' }}>Review your "Current/Most Recent Career Values" list.</p>
                    <ul>
                        <li style={{ marginLeft: '21px', marginTop: '15px', fontSize: '18px' }} >Repeat Steps 1-4 above, thinking only about your current career (or most recent job).</li>
                        <li style={{ marginLeft: '21px', marginTop: '15px', fontSize: '18px' }} >
                            <b style={{ color: '#37B048' }}> Finally, compare your "Whole Life/Personal Values" list with your "Current/Most Recent Career
                                Values" list.</b> What similarities and differences do you see? What do you believe contributes
                            to these similarities and differences? For each value that is listed on one page and not the
                            other, put a box around that value. Use another page to make notes, write a story, or draw
                            pictures about these differences. If all the values are the same in both lists, use another page
                            to make notes, write a story, or draw pictures about how that feels.
                        </li>
                    </ul>
                </div>

                <div style={{ marginBottom: '20px' }}>
                    <h3 style={{ marginTop: '15px', fontSize: '21px', fontWeight: '600' }} >Understanding Your Ideal Career Values</h3>
                    <p style={{ marginTop: '15px', fontSize: '18px' }}>Finally, review your "Ideal Career Values" list.</p>
                    <ul>
                        <li style={{ marginLeft: '21px', marginTop: '10px', fontSize: '18px' }} >Complete Steps 1-3 above.</li>
                        <li style={{ marginLeft: '21px', marginTop: '10px', fontSize: '18px' }} >
                            <b style={{ color: '#37B048' }}> For Step 4, consider how your ideals career values would fit in your daily life if you were able
                                to live them.</b> What would have to change? How might your life look different if these values were
                            met? On the back of the page, make notes, write a story, or draw pictures about those changes and
                            how that feels.
                        </li>
                        <li style={{ marginLeft: '21px', marginTop: '10px', fontSize: '18px' }} >
                            Finally, compare your "Whole Life/Personal Values" list with your "Current/Most Recent Career Values"
                            list and your "Ideal Career Values" list.<b style={{ color: '#37B048' }}> What similarities and differences do you see? What do you believe
                                contributes to these similarities and differences? </b>How does your "Current/Most Recent Career Values"
                            list and your "Ideal Career Values" list compare with each other? <b style={{ color: '#37B048' }}>If they are dramatically different, to what
                                degree do you think this does or did impact your work life? On the back of the page, make notes, write a
                                story, or draw pictures about the impact and how that feels.</b>
                        </li>
                    </ul>
                </div>

                <div className='next-steps' style={{ marginBottom: '20px' }}>
                    <h3 style={{ marginTop: '10px', fontSize: '21px', fontWeight: '600' }} >Next Steps</h3>
                    <p style={{ marginTop: '10px', fontSize: '18px' }}>It may be helpful to view these results and exercises more than once. You may also wish to go on to the Next Steps on the website 	<a className='tw-cursor-pointer tw-underline tw-text-blue-700' target='_blank' href=' https://lifecareervalues.web.app/nextSteps'>Next Steps</a> .</p>

                </div>

                <div className='important-whole-life-values page-class' >
                    <h2 style={{ fontSize: '21px', fontWeight: '700' }} >{props.name} - Your Most Important Whole Life Values</h2>
                    <ul style={{ marginLeft: '8px' }}>
                        {props.finalValuesData?.t1.map((value, index) => (
                            <li style={{ marginTop: '15px', fontSize: '21px', fontWeight: '600' }} key={index}>{index + 1} - {value.option_value}</li>
                        ))}
                    </ul>
                </div>

                <div className='important-current-career page-class'>
                    <h2 style={{ marginTop: '15px', fontSize: '21px', fontWeight: '700' }} >{props.name} - Your Most Important Current/Most Recent Career Values</h2>
                    <ul style={{ marginLeft: '8px' }}>
                        {props.finalValuesData?.t2.map((value, index) => (
                            <li style={{ marginTop: '15px', fontSize: '21px', fontWeight: '600' }} key={index}>{index + 1} - {value.option_value}</li>
                        ))}
                    </ul>
                </div>

                <div style={{ marginTop: '40px' }} className='important-ideal-career-values page-class'>
                    <h2 style={{ marginTop: '15px', fontSize: '21px', fontWeight: '700' }} >{props.name} - Your Most Important Ideal Career Values</h2>
                    <ul style={{ marginLeft: '8px' }}>
                        {props.finalValuesData?.t2.map((value, index) => (
                            <li style={{ marginTop: '15px', fontSize: '21px', fontWeight: '600' }} key={index}>{index + 1} - {value.option_value}</li>
                        ))}
                    </ul>
                </div>

            </div>
        </div>
    )
}

export default LifeCareerPdf