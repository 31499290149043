import AboutMe from '@Pages/landing-page/components/about-me/AboutMe'
import Features from '@Pages/landing-page/components/features/Features'
import FiveSteps from '@Pages/landing-page/components/five-steps/FiveSteps'
import QuotesSection from '@Pages/landing-page/components/quotes-section/QuotesSection'
import React from 'react'
import "./HomeVideo.scss"

const HomeVideo = () => {
    return (
        <div className='home-video-container'>
            <div className='img-container md:tw-pl-24 md:tw-pt-24 tw-pl-4 md:tw-grid md:tw-grid-cols-2 tw-flex tw-justify-center tw-items-center tw-flex-col tw-gap-5'>
                <div className='home-video-left-section'>
                    <p className='tw-text-left tw-text-[#ffffff]  md:tw-text-[55px] tw-text-[36px] tw-font-bold tw-mt-6'>Restart Your Career<br /> To Be More  Successful</p>
                    {/* <p className='tw-text-left tw-text-white tw-underline tw-underline-offset-4 tw-decoration-[#37B048] tw-decoration-2 tw-mt-4 '>Get Started Now</p> */}
                </div>
                <div className='home-video-right-section md:tw-flex tw-justify-center tw-hidden  '>
                    <iframe width="550" height="300" src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                <div className='home-video-right-section n md:tw-hidden tw-flex tw-justify-center  '>
                    <iframe width="300" height="250" src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>
            {/* Features Section */}
            {/* <Features /> */}
            {/* Quotes */}
            {/* <QuotesSection /> */}
            {/* About Me */}
            {/* <AboutMe /> */}
            {/* Steps to success */}
            {/* <FiveSteps /> */}
        </div>
    )
}

export default HomeVideo