import { LOCAL_STORAGE_DATA_KEYS } from "@Constants/localstorageDataModel";
import { IAPIResponse } from "@Src/types/api-response-interface";
import { getDataFromLocalStorage, removeDataFromLocalStorage } from "@Src/utils/globalUtilities";
import axios from "axios";
import { toast } from "react-toastify";


export const MODULES_API_MAP = {

    AUTHENTICATION: process.env.REACT_APP_UNIVERZE_BASE_API_URL,
    BASE_API_ROUTE: process.env.REACT_APP_LIFE_CAREER_VALUES_BASE_API_URL,
    BACKEND_API_URL: process.env.REACT_APP_BACKEND_BASE_URL,

}


export function httpService(moduleBaseApiUrl: string | undefined = "", endpoint: string, showErrorToast = true, requireAuth = true, customHeaders: any = undefined, rawResponse = false, signal?: AbortSignal) {
    const httpInstance = axios.create({});
    if (requireAuth) {
        httpInstance.interceptors.request.use((config) => {
            let key = getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.AUTH_KEY);
            config.headers['key'] = key;
            if (customHeaders && Object.keys(customHeaders).length > 0) {
                config.headers = {
                    ...customHeaders
                }
            }
            config.baseURL = moduleBaseApiUrl;
            return config;
        });
    }

    async function GET() {
        try {
            let config: any = {};
            if (signal) {
                config["signal"] = signal;
            }
            let apiResponse = await httpInstance.get<IAPIResponse, any>(endpoint, { ...config });
            if (rawResponse) {
                return apiResponse;
            }
            return apiResponse?.data;
        } catch (err: any) {
            if (showErrorToast) {
                if (err?.response.data.errors && err?.response.data?.errors[0]?.message === 'Not authorized') {
                    removeDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.AUTH_KEY);
                    localStorage.clear()
                    window.location.href = '/'

                }
                if (err?.response.data.errors && err?.response.data?.errors[0]?.message)
                    toast.error(err?.response.data?.errors[0]?.message);
                else
                    toast.error('Something went wrong, please try later');
            } else {
                return Promise.reject(err);
            }
        }
    }

    async function POST(requestObject: any) {
        try {
            let config: any = {};
            if (signal) {
                config["signal"] = signal;
            }
            let apiResponse = await httpInstance.post<IAPIResponse, any>(endpoint, requestObject, { ...config });
            if (rawResponse) {
                return apiResponse;
            }
            return apiResponse?.data;
        } catch (err: any) {
            if (showErrorToast) {
                if (err?.response.data.errors && err?.response.data?.errors[0]?.message === 'Not authorized') {
                    removeDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.AUTH_KEY);
                    localStorage.clear()
                    window.location.href = '/'

                }
                if (err?.response.data.errors && err?.response.data?.errors[0]?.message)
                    toast.error(err?.response.data?.errors[0]?.message);
                else
                    toast.error('Something went wrong, please try later');
            } else {
                return Promise.reject(err);
            }
        }
    }

    async function PUT(requestObject: any) {
        try {
            let config: any = {};
            if (signal) {
                config["signal"] = signal;
            }
            let apiResponse = await httpInstance.put<IAPIResponse, any>(endpoint, requestObject, { ...config });
            if (rawResponse) {
                return apiResponse;
            }
            return apiResponse?.data;
        } catch (err: any) {
            if (showErrorToast) {
                if (err?.response.data.errors && err?.response.data?.errors[0]?.message === 'Not authorized') {
                    removeDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.AUTH_KEY);
                    localStorage.clear()
                    window.location.href = '/'

                }
                if (err?.response.data.errors && err?.response.data?.errors[0]?.message)
                    toast.error(err?.response.data?.errors[0]?.message);
                else
                    toast.error('Something went wrong, please try later');
            } else {
                return Promise.reject(err);
            }
        }
    }

    async function DELETE() {
        try {
            let config: any = {};
            if (signal) {
                config["signal"] = signal;
            }
            let apiResponse = await httpInstance.delete<IAPIResponse, any>(endpoint, { ...config });
            if (rawResponse) {
                return apiResponse;
            }
            return apiResponse?.data;
        } catch (err: any) {
            if (showErrorToast) {
                if (err?.response.data.errors && err?.response.data?.errors[0]?.message === 'Not authorized') {
                    removeDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.AUTH_KEY);
                    localStorage.clear()
                    window.location.href = '/'

                }
                if (err?.response.data.errors && err?.response.data?.errors[0]?.message)
                    toast.error(err?.response.data?.errors[0]?.message);
                else
                    toast.error('Something went wrong, please try later');
            } else {
                return Promise.reject(err);
            }
        }
    }

    async function PATCH(requestObject?: any) {
        try {
            let config: any = {};
            if (signal) {
                config["signal"] = signal;
            }
            let apiResponse = await httpInstance.patch<IAPIResponse, any>(endpoint, requestObject, { ...config });
            if (rawResponse) {
                return apiResponse;
            }
            return apiResponse?.data;
        } catch (err: any) {
            if (showErrorToast) {
                if (err?.response.data.errors && err?.response.data?.errors[0]?.message === 'Not authorized') {
                    removeDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.AUTH_KEY);
                    localStorage.clear()
                    window.location.href = '/'

                }
                if (err?.response.data.errors && err?.response.data?.errors[0]?.message)
                    toast.error(err?.response.data?.errors[0]?.message);
                else
                    toast.error('Something went wrong, please try later');
            } else {
                return Promise.reject(err);
            }
        }
    }

    return { GET, POST, PUT, PATCH, DELETE }
}
