export const AppRouteURLs = {
    LANDING_PAGE: '/landingPage',
    HOME_VIDEO: '/home-video',
    LOGIN: '/login',
    FAQ: '/faq',
    ASSESSMENT: '/assessment',
    NEXT_STEPS: '/nextSteps',
    DASHBOARD: '/dashboard',
    ABOUT: '/about',

};
