import jwt_decode from 'jwt-decode';
import jwt_encode from 'jwt-encode';

import { LOCAL_STORAGE_DATA_KEYS } from '@Constants/localstorageDataModel';

export const encodeString = (value: any) => {
    const JWT_ENCODE_SECRET = process.env.REACT_APP_JWT_ENCODE_SECRET!;
    return jwt_encode(value, JWT_ENCODE_SECRET);
}

// export const decodeString = (value: string) => {
//     return jwt_decode(value);
// }

export const setDataOnLocalStorage = (key: string, value: string) => {
    return localStorage.setItem(key, value);
}

export const getDataFromLocalStorage = (key: string) => {
    const value = localStorage.getItem(key);
    if (value === null) {
        return "";
    }
    return value;
}

export const removeDataFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
}

export const isMobile = (): boolean => {
    if (window.screen.width <= 992) {
        return true;
    }
    return false;
}

/**
     * @param object - Object of objects
     * @returns - Array of objects
     */

export const getArrayOfObjects = (object: any): Array<any> => {
    if (object) {
        return Object.keys(object).map(key => {
            return object[key];
        })
    } else {
        return [];
    }
}


